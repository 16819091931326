import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  DialogContentText,
  Chip,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import KeyboardReturnRoundedIcon from '@material-ui/icons/KeyboardReturnRounded';
import { filter, find, map } from 'lodash';
import Swal from 'sweetalert2';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  cover: {
    borderRadius: '16px',
    width: '70%',
    height: '200px',
    border: '2px solid #ffe4e4',
    overflow: 'hidden',
    margin: '12px auto',
    position: 'relative',

    '&::before': {
      display: 'block',
      position: 'absolute',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      backgroundImage:
        'linear-gradient(90deg, transparent 5px, rgb(241 241 241) 5px)',
      backgroundSize: '6px 100%',
      backgroundRepeat: 'repeat',
      transform: 'rotate(39deg) scale(5)',
      height: '600px',
      content: 'close-quote',
    },
  },
  coverTitle: {
    position: 'absolute',
    top: '12px',
    left: '12px',
    zIndex: '100',
  },
  imageBtns: {
    position: 'absolute',
    right: '12px',
    bottom: '12px',
    zIndex: '100',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '12px',
  },
  emptyCover: {
    fontSize: '3rem',
    color: '#999',
    fontWeight: '600',
    textAlign: 'center',
    marginTop: '60px',
  },
  coverImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  upload: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
  },
  images: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '12px',
  },
  image: {
    width: '200px',
    height: '200px',
    position: 'relative',
    borderRadius: '16px',
    border: '2px solid #ffe4e4',
    overflow: 'hidden',

    '& > img': {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
    },
  },
});

const ReservationObjectImgSettingModal = (props) => {
  const {
    isOpen,
    close,
    setCover,
    uploadImg,
    deleteImg,
    reservationObject,
    isLoading,
  } = props;
  const classes = useStyles();
  const [pageStep, setPageStep] = useState('list');
  const [coverImg, setCoverImg] = useState();
  const [images, setImages] = useState();
  const [uploadFile, setUploadFile] = useState();
  const [uploadFileIsCover, setUploadFileIsCover] = useState(false);

  useEffect(() => {
    if (
      reservationObject &&
      reservationObject.reservationImageList.length > 0
    ) {
      const findCoverImg = find(
        reservationObject.reservationImageList,
        (reservationImage) => reservationImage.isCover === true,
      );

      if (findCoverImg) {
        setCoverImg(findCoverImg);
      }

      setImages(
        filter(
          reservationObject.reservationImageList,
          (reservationImage) => reservationImage.isCover !== true,
        ),
      );
    } else {
      setCoverImg(undefined);
      setImages(undefined);
    }

    return () => {
      setPageStep('list');
      setCoverImg(undefined);
      setImages(undefined);
    };
  }, [reservationObject]);

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      close();
    }
  };

  const changeStepToUpload = () => {
    setPageStep('upload');
  };

  const changeStepToList = () => {
    setPageStep('list');
  };

  const handleFileChange = (e) => {
    setUploadFile(e.target.files[0]);
  };

  const handleUploadFile = () => {
    uploadImg(reservationObject.id, uploadFile, uploadFileIsCover).then(() => {
      setPageStep('list');
      setUploadFile(undefined);
      setUploadFileIsCover(false);
    });
  };

  const handleDeleteImg = (objectId, imgId) => {
    Swal.fire({
      title: '確定要刪除嗎？',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: '取消',
      confirmButtonText: '確定',
    }).then((rs) => {
      if (rs.value) {
        deleteImg(objectId, imgId);
      }
    });
  };

  const handleSetCover = (objectId, imgId) => {
    setCover(objectId, imgId);
  };

  if (!reservationObject) {
    return null;
  }

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="md"
      onClose={handleClose}>
      <DialogTitle>設定圖片</DialogTitle>
      {pageStep === 'list' && (
        <DialogContent>
          <DialogContentText>
            設定 {reservationObject.name} 封面和多張圖片
          </DialogContentText>

          <div className={classes.cover}>
            <div className={classes.coverTitle}>
              <Chip
                size="small"
                color="primary"
                label="封面"
                icon={<CropOriginalIcon />}
              />
            </div>

            {!coverImg && <div className={classes.emptyCover}>無封面</div>}

            {coverImg && (
              <img className={classes.coverImg} src={coverImg.imageUrl} />
            )}

            {coverImg && (
              <div className={classes.imageBtns}>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<HighlightOffIcon />}
                  disabled={isLoading}
                  onClick={() => {
                    handleDeleteImg(reservationObject.id, coverImg.imageId);
                  }}>
                  刪除
                </Button>
              </div>
            )}
          </div>

          <div className={classes.images}>
            {images &&
              map(images, (image) => (
                <div key={image.imageId} className={classes.image}>
                  <img src={image.imageUrl} />

                  <div className={classes.imageBtns}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<CropOriginalIcon />}
                      disabled={isLoading}
                      onClick={() => {
                        handleSetCover(reservationObject.id, image.imageId);
                      }}>
                      設為封面
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      startIcon={<HighlightOffIcon />}
                      disabled={isLoading}
                      onClick={() => {
                        handleDeleteImg(reservationObject.id, image.imageId);
                      }}>
                      刪除
                    </Button>
                  </div>
                </div>
              ))}
          </div>
        </DialogContent>
      )}

      {pageStep === 'upload' && (
        <DialogContent>
          <DialogContentText>上傳圖片</DialogContentText>

          <div className={classes.upload}>
            <input
              type="file"
              onChange={handleFileChange}
              accept="image/*"
              disabled={isLoading}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={uploadFileIsCover}
                  color="primary"
                  onChange={(e) => {
                    setUploadFileIsCover(e.target.checked);
                  }}
                  disabled={isLoading}
                />
              }
              label="設為封面"
            />

            <Button
              variant="contained"
              color="primary"
              startIcon={<PublishRoundedIcon />}
              disabled={isLoading || !uploadFile}
              onClick={handleUploadFile}>
              上傳
            </Button>
          </div>
        </DialogContent>
      )}

      <DialogActions>
        {pageStep === 'list' && (
          <Button
            onClick={changeStepToUpload}
            color="primary"
            variant="contained"
            startIcon={<PublishRoundedIcon />}
            disabled={isLoading}>
            上傳圖片
          </Button>
        )}

        {pageStep === 'upload' && (
          <Button
            onClick={changeStepToList}
            color="default"
            variant="contained"
            startIcon={<KeyboardReturnRoundedIcon />}
            disabled={isLoading}>
            取消上傳
          </Button>
        )}

        <Button onClick={handleClose} color="primary">
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReservationObjectImgSettingModal;
