import React, { useState, useEffect } from 'react';
import { Button, TableCell, TableRow, Chip, Avatar } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import moment from 'moment';
import CropDinRoundedIcon from '@material-ui/icons/CropDinRounded';
import {
  UserProductPackageOrderStatus,
  UserProductPackageOrderStatusText,
} from '../../data/common';
import { forEach } from 'lodash';
import { findCover } from '../../Util/Util';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import GroupProductTableCell from './GroupProductTableCell';

const UserProductPackageOrderTableRow = (props) => {
  const { userProductPackage, openDetail } = props;
  const [cover, setCover] = useState();
  const [countProducts, setCountProducts] = useState();

  useEffect(() => {
    if (userProductPackage) {
      setCover(
        findCover(
          userProductPackage.userProductPackageDetail.packageInfo
            .packageProductImageList,
        ),
      );

      let newCountProducts = {};

      forEach(
        userProductPackage.userProductPackageDetail.packageDetail,
        (userProduct) => {
          const groupKey = userProduct.isGift
            ? 'gift_'
            : '' + userProduct.bonusProductId;

          if (!newCountProducts[groupKey]) {
            newCountProducts[groupKey] = {
              name: userProduct.productInfo.name,
              total: 0,
              isGift: userProduct.isGift,
            };
          }

          newCountProducts[groupKey].total =
            newCountProducts[groupKey].total + 1;
        },
      );

      setCountProducts(newCountProducts);
    }
  }, [userProductPackage]);

  const handleOpenDetail = () => {
    openDetail(userProductPackage);
  };

  return (
    <TableRow>
      <TableCell component="th">
        {cover && <Avatar src={cover.imageUrl} />}

        {!cover && (
          <Avatar>
            <CropDinRoundedIcon />
          </Avatar>
        )}
      </TableCell>
      <TableCell component="th">
        {userProductPackage.userProductPackageDetail.packageInfo.name}
      </TableCell>
      <TableCell component="th">
        {userProductPackage.userInfo.realName}
      </TableCell>
      <TableCell>{userProductPackage.staffInfo.realName}</TableCell>
      <TableCell>
        {moment(userProductPackage.createDate).format('YYYY-MM-DD HH:mm:ss')}
      </TableCell>
      <TableCell>
        <Chip
          color={
            userProductPackage.status === UserProductPackageOrderStatus.USED
              ? 'primary'
              : 'secondary'
          }
          label={UserProductPackageOrderStatusText[userProductPackage.status]}
          icon={
            userProductPackage.status === UserProductPackageOrderStatus.USED ? (
              <CheckRoundedIcon />
            ) : (
              <CloseRoundedIcon />
            )
          }
          size="small"
        />
      </TableCell>
      <TableCell>
        {userProductPackage.status === UserProductPackageOrderStatus.CANCEL && (
          <span>{userProductPackage.modifiedUserInfo.realName}</span>
        )}
      </TableCell>
      <TableCell>
        {userProductPackage.status === UserProductPackageOrderStatus.CANCEL && (
          <span>
            {moment(userProductPackage.modifiedDate).format(
              'YYYY-MM-DD HH:mm:ss',
            )}
          </span>
        )}
      </TableCell>
      <GroupProductTableCell groupProducts={countProducts} />
      <TableCell align="right">
        <Button
          variant="contained"
          color="default"
          size="small"
          startIcon={<AssignmentIcon />}
          style={{ marginRight: '12px' }}
          onClick={handleOpenDetail}>
          檢視
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default UserProductPackageOrderTableRow;
