import React, { useEffect, useState } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  Grid,
  Button,
  Paper,
  Typography,
  Divider,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { trim, unescape } from 'lodash';
import Swal from 'sweetalert2';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import KeyboardReturnOutlinedIcon from '@material-ui/icons/KeyboardReturnOutlined';
import { alertError } from '../../Util/Util';
import { ProductPackageStatus } from '../../data/common';
import Editor from '../../components/Editor';
import { AWS_BONUS_PRODUCT_POSITION } from '../../data/aws';
import productPackageAPI from '../../middleware/productPackageAPI';

const ProductPackage = (props) => {
  const { match } = props;
  const { id } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isCreate, setIsCreate] = useState(() => {
    return trim(id) === '';
  });
  const [form, setForm] = useState({
    id: 0,
    status: 0,
    name: '',
    description: '',
    barcode: '',
  });

  useEffect(() => {
    const checkIsCreateRs = trim(id) === '';
    setIsCreate(checkIsCreateRs);

    if (!checkIsCreateRs) {
      getProductPackage();
    }
  }, [id]);

  const getProductPackage = () => {
    setIsLoading(true);

    return productPackageAPI
      .getProductPackage(id)
      .then(
        (rs) => {
          setForm({
            id: rs.id,
            name: rs.name,
            description: unescape(rs.description),
            status: rs.status,
            barcode: rs.barcode,
          });
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const handleChangeForm = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (trim(form.name) === '') {
      Swal.fire({
        title: '請輸入名稱',
        icon: 'info',
      });

      return false;
    }

    setIsLoading(true);

    const promise = isCreate
      ? productPackageAPI.createProductPackage(form)
      : productPackageAPI.updateProductPackage(id, form);

    promise.then(
      (rs) => {
        Swal.fire({
          title: '儲存完成',
          icon: 'success',
        });

        history.push('/ProductPackages/ProductPackageList');
      },
      (error) => {
        alertError(error);
        setIsLoading(false);
      },
    );
  };

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={match.url}></BreakCrumbs>

      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Typography variant="h5" color="textPrimary" gutterBottom>
          {isCreate ? '建立套券' : '修改套券'}
        </Typography>

        <Divider light />

        <Box p={3}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            spacing={2}>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth={true}
                label="名稱"
                value={form.name || ''}
                onChange={(e) => {
                  handleChangeForm('name', e.target.value);
                }}
                disabled={isLoading}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth={true}
                label="Barcode"
                value={form.barcode || ''}
                onChange={(e) => {
                  handleChangeForm('barcode', e.target.value);
                }}
                disabled={isLoading}
              />
            </Grid>

            <Grid item xs={2}>
              <FormControl fullWidth disabled={isLoading}>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      color="primary"
                      checked={form.status === ProductPackageStatus.ONLINE}
                      onChange={(e) => {
                        handleChangeForm(
                          'status',
                          e.target.checked
                            ? ProductPackageStatus.ONLINE
                            : ProductPackageStatus.OFFLINE,
                        );
                      }}
                    />
                  }
                  label="上架"
                  labelPlacement="bottom"
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Editor
                content={form.description}
                setContent={(newContent) => {
                  handleChangeForm('description', newContent);
                }}
                imageS3Position={{
                  bucket: AWS_BONUS_PRODUCT_POSITION.description.bucket,
                  dir: AWS_BONUS_PRODUCT_POSITION.description.dir,
                  acl: AWS_BONUS_PRODUCT_POSITION.description.acl,
                }}
                editorProps={{
                  height: '350',
                  placeholder: '套券說明',
                }}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Box>

        <Divider light />

        <Box p={1} mt={2} mb={2} textAlign="right">
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ marginRight: '24px' }}
            startIcon={<SaveOutlinedIcon />}
            disabled={isLoading}
            onClick={handleSubmit}>
            {isCreate ? '建立' : '修改'}
          </Button>
          <Button
            variant="contained"
            color="default"
            size="large"
            startIcon={<KeyboardReturnOutlinedIcon />}
            disabled={isLoading}
            onClick={() => {
              history.push('/ProductPackages/ProductPackageList');
            }}>
            回列表
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default ProductPackage;
