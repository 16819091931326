import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Chip } from '@material-ui/core';
import moment from 'moment';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import FreeBreakfastOutlinedIcon from '@material-ui/icons/FreeBreakfastOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import DirectionsWalkOutlinedIcon from '@material-ui/icons/DirectionsWalkOutlined';
import Swal from 'sweetalert2';
import QRCodeImg from '../QRCodeImg';
import LocalActivityRoundedIcon from '@material-ui/icons/LocalActivityRounded';
import EmojiPeopleRoundedIcon from '@material-ui/icons/EmojiPeopleRounded';
import RepeatRoundedIcon from '@material-ui/icons/RepeatRounded';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1.5, '0'),
    padding: theme.spacing(1),
    backgroundColor: '#eee',
    borderRadius: theme.spacing(1),
    position: 'relative',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  code: {
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: '600',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    letterSpacing: '1px',
    flexBasis: '130px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
  },
  date: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexBasis: '300px',
  },
  dateItem: {
    backgroundColor: '#dbdbdb',
    textAlign: 'left',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  dateEndItem: {
    marginLeft: 'auto',
  },
  dateYear: {
    color: '#999',
    fontSize: '0.8rem',
    textAlign: 'left',
    borderBottom: '1px solid #fff',
  },
  dateMMDD: {
    color: '#000',
    fontSize: '1.8rem',
    fontWeight: '500',
  },
  action: {
    flexBasis: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tags: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 0,
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
}));

const DateStatusWaitingChip = () => (
  <Chip
    color="primary"
    size="small"
    icon={<FreeBreakfastOutlinedIcon />}
    label="尚未開始"
  />
);

const DateStatusProgressChip = () => (
  <Chip
    color="secondary"
    size="small"
    icon={<DirectionsWalkOutlinedIcon />}
    label="正在進行中"
  />
);

const DateStatusOverChip = () => (
  <Chip
    color="default"
    size="small"
    icon={<CheckCircleOutlinedIcon />}
    label="結束"
  />
);

const ReferralCodeCard = (props) => {
  const {
    referralCode,
    isLoading,
    setEditReferralCode,
    deleteReferralCode,
  } = props;
  const classes = useStyles();
  const [cardStatus, setCardStatus] = useState();

  const handleEdit = () => {
    setEditReferralCode(referralCode);
  };

  const handleDelete = () => {
    Swal.fire({
      title: '確認要刪除嗎？',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((rs) => {
      if (rs.value) {
        deleteReferralCode(referralCode.id);
      }
    });
  };

  useEffect(() => {
    const momentStartDate = moment(referralCode.startDate);
    const momentEndDate = moment(referralCode.endDate).endOf('day');
    const nowMomentDate = moment();
    const dateStatus = nowMomentDate.isBefore(momentStartDate)
      ? 'waiting'
      : nowMomentDate.isAfter(momentStartDate) &&
        nowMomentDate.isBefore(momentEndDate)
      ? 'progress'
      : 'over';

    setCardStatus({
      dateStatus: dateStatus,
      startDate: {
        date: momentStartDate.format('YYYY-MM-DD'),
        yyyy: momentStartDate.format('YYYY'),
        mmdd: momentStartDate.format('MM-DD'),
      },
      endDate: {
        date: momentEndDate.format('YYYY-MM-DD'),
        yyyy: momentEndDate.format('YYYY'),
        mmdd: momentEndDate.format('MM-DD'),
      },
    });
  }, [referralCode]);

  return (
    <div className={classes.root}>
      <div className={classes.tags}>
        {referralCode.isForEvent && (
          <Chip
            label="活動"
            icon={<LocalActivityRoundedIcon />}
            color="primary"
            size="small"
          />
        )}

        {referralCode.isUnlimited && (
          <Chip
            label="重複領取"
            icon={<RepeatRoundedIcon />}
            color="secondary"
            size="small"
          />
        )}

        {referralCode.isForEvent === false &&
          referralCode.isUnlimited === false && (
            <Chip
              label="推廣"
              icon={<EmojiPeopleRoundedIcon />}
              color="default"
              size="small"
            />
          )}
      </div>
      <div className={classes.code}>
        {referralCode.code}
        <QRCodeImg qrCodeValue={referralCode.code} />
      </div>
      <div className={classes.date}>
        <div className={classes.dateItem}>
          <div className={classes.dateYear}>
            {cardStatus && cardStatus.startDate.yyyy}
          </div>
          <div className={classes.dateMMDD}>
            {cardStatus && cardStatus.startDate.mmdd}
          </div>
        </div>
        <div className={`${classes.dateItem} ${classes.dateEndItem}`}>
          <div className={classes.dateYear}>
            {cardStatus && cardStatus.endDate.yyyy}
          </div>
          <div className={classes.dateMMDD}>
            {cardStatus && cardStatus.endDate.mmdd}
          </div>
        </div>
      </div>
      <div className={classes.action}>
        <div>
          {cardStatus && cardStatus.dateStatus === 'waiting' && (
            <DateStatusWaitingChip />
          )}
          {cardStatus && cardStatus.dateStatus === 'progress' && (
            <DateStatusProgressChip />
          )}
          {cardStatus && cardStatus.dateStatus === 'over' && (
            <DateStatusOverChip />
          )}
        </div>
        <div>
          <Button
            color="secondary"
            startIcon={<ClearOutlinedIcon />}
            onClick={handleDelete}
            disabled={isLoading}>
            刪除
          </Button>
          <Button
            color="primary"
            startIcon={<EditOutlinedIcon />}
            onClick={handleEdit}
            disabled={isLoading}>
            編輯
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReferralCodeCard;
