import instance from './API';
import { isArray, join, trim, escape } from 'lodash';

const productPackageAPI = {
  getProductPackage: (id) => {
    return instance.get(`/ProductPackage/${id}`);
  },
  sendProductPackageToUser: (packageId, userId) => {
    return instance.post(`/AddPackageToUser/${packageId}`, null, {
      params: {
        customerId: userId,
      },
    });
  },
  getUserProductPackage: (orderNumber, userId) => {
    return instance.get(`/UserProductPackage`, {
      params: {
        orderNumber: orderNumber,
        customerId: userId,
      },
    });
  },
  cancelUserProductPackage: (orderNumber, userId) => {
    return instance.delete('/CancelProductPackage', {
      params: {
        orderNumber: orderNumber,
        customerId: userId,
      },
    });
  },
  getUserProductPackages: (page, count, status) => {
    return instance.get('/UserProductPackageList', {
      params: {
        page: page,
        count: count,
        status: status,
      },
    });
  },
  getProductPackages: (page, count, search) => {
    return instance.get('/ProductPackageList', {
      params: {
        page: page,
        count: count,
        status: trim(search.status) !== '' ? search.status : '-1',
      },
    });
  },
  getProductPackage: (id) => {
    return instance.get(`/ProductPackage/${id}`);
  },
  createProductPackage: (productPackage) => {
    const createParams = new URLSearchParams();

    createParams.append('Status', productPackage.status);
    createParams.append('Name', productPackage.name);
    createParams.append('Description', escape(productPackage.description));
    createParams.append(
      'Barcode',
      trim(productPackage.barcode) !== '' ? productPackage.barcode : '',
    );

    return instance.post(`/ProductPackage`, createParams);
  },
  updateProductPackage: (id, productPackage) => {
    const updateParams = new URLSearchParams();

    updateParams.append('Status', productPackage.status);
    updateParams.append('Name', productPackage.name);
    updateParams.append('Description', escape(productPackage.description));
    updateParams.append(
      'Barcode',
      trim(productPackage.barcode) !== '' ? productPackage.barcode : '',
    );

    return instance.put(`/ProductPackage/${id}`, updateParams);
  },
  uploadProductPackageImg: (id, imgFile, isCover) => {
    const uploadParams = new FormData();

    uploadParams.append('Image', imgFile);
    uploadParams.append('IsCover', isCover);
    uploadParams.append('ProductPackageId', id);

    return instance.post(`/ProductPackage/Image`, uploadParams);
  },
  deleteProductPackageImg: (imgId) => {
    return instance.delete(`/ProductPackage/Image/${imgId}`);
  },
  setProductPackageImgCover: (imgId) => {
    return instance.patch(`/ProductPackage/Image/${imgId}`);
  },
  removePackageProduct: (packageProductId) => {
    const removeIds = isArray(packageProductId)
      ? join(packageProductId, ',')
      : packageProductId;

    return instance.delete(`/ProductPackage/Product/${removeIds}`);
  },
  addProduct: (productPackageId, bonusProductId, isGift, count, expriyDay) => {
    const addParams = new URLSearchParams();

    addParams.append('ProductPackageId', productPackageId);
    addParams.append('BonusProductId', bonusProductId);
    addParams.append('IsGift', isGift === true ? true : false);
    addParams.append('Count', count);

    if (trim(expriyDay) !== '' && isGift === true) {
      addParams.append('PackageExpriyDateDay', expriyDay);
    }

    return instance.post('/ProductPackage/Product', addParams);
  },
};

export default productPackageAPI;
