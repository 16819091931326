import { TableCell } from '@material-ui/core';
import React from 'react';
import RedeemIcon from '@material-ui/icons/Redeem';
import { map } from 'lodash';

const GroupProductTableCell = (props) => {
  const { groupProducts } = props;

  return (
    <TableCell>
      {map(groupProducts, (groupProduct, groupProductIndex) => {
        return (
          <div key={groupProductIndex}>
            {groupProduct.name} X {groupProduct.total}
            {groupProduct.isGift && (
              <RedeemIcon
                style={{
                  width: '20px',
                  color: '#8ab33c',
                  verticalAlign: 'bottom',
                  marginLeft: '6px',
                }}
              />
            )}
          </div>
        );
      })}
    </TableCell>
  );
};

export default GroupProductTableCell;
