import React, { useState, useEffect } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TableLoading from '../../components/TableLoading';
import { alertError } from '../../Util/Util';
import TableEmpty from '../../components/TableEmpty';
import { map, find, trim } from 'lodash';
import { useHistory } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { LIST_PAGE_COUNT, ProductPackageStatusText } from '../../data/common';
import ProductPackageTable from '../../components/ProductPackages/ProductPackageTable';
import ProductPackageImgSettingModal from '../../components/ProductPackages/ProductPackageImgSettingModal';
import ProductPackageProductSettingModal from '../../components/ProductPackages/ProductPackageProductSettingModal';
import bonusProductAPI from '../../middleware/bonusProductAPI';
import productPackageAPI from '../../middleware/productPackageAPI';
import Swal from 'sweetalert2';

const ProductPackageList = (props) => {
  const { match } = props;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [productPackages, setProductPackages] = useState();
  const [productPackageTotal, setProductPackageTotal] = useState();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState({
    status: '',
  });
  const [settingProductPackage, setSettingProductPackage] = useState();
  const [isImgModalOpen, setIsImgModalOpen] = useState(false);
  const [isProductsModalOpen, setIsProductsModalOpen] = useState(false);

  useEffect(() => {
    handleSearch();
  }, [page]);

  const handleChangeSearch = (e) => {
    const changeItem = e.target.name;
    const changeValue = e.target.value;

    setSearch({
      ...search,
      [changeItem]: changeValue,
    });
  };

  const handleSearch = (refreshId = undefined) => {
    setIsLoading(true);

    productPackageAPI
      .getProductPackages(page, LIST_PAGE_COUNT, search)
      .then(
        (rs) => {
          setProductPackages(rs.productPackageList);
          setProductPackageTotal(rs.totalCount);

          if (refreshId) {
            const findRefreshProductPackage = find(
              rs.productPackageList,
              (productPackage) => productPackage.id === refreshId,
            );

            setSettingProductPackage(findRefreshProductPackage);
          }
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const toEditProductPackage = (id) => {
    history.push(`/ProductPackages/ProductPackage/${id}`);
  };

  const toCreateProductPackage = () => {
    history.push('/ProductPackages/ProductPackage');
  };

  const uploadImg = (id, uploadFile, isCover) => {
    setIsLoading(true);

    return productPackageAPI
      .uploadProductPackageImg(id, uploadFile, isCover)
      .then(
        (rs) => {
          refreshSettingProductPackage(id);
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const deleteImg = (id, imgId) => {
    setIsLoading(true);

    return productPackageAPI
      .deleteProductPackageImg(imgId)
      .then(
        () => {
          refreshSettingProductPackage(id);
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const setCover = (id, imgId) => {
    setIsLoading(true);

    return productPackageAPI
      .setProductPackageImgCover(imgId)
      .then(
        () => {
          refreshSettingProductPackage(id);
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const refreshSettingProductPackage = (id) => {
    handleSearch(id);
  };

  const handleOpenImgModal = (id) => {
    const findProductPackage = find(
      productPackages,
      (productPackage) => productPackage.id === id,
    );

    setSettingProductPackage(findProductPackage);
    setIsImgModalOpen(true);
  };

  const handleImgModalClose = () => {
    setIsImgModalOpen(false);
    setSettingProductPackage(undefined);
  };

  const handleOpenProductsModal = (id) => {
    const findProductPackage = find(
      productPackages,
      (productPackage) => productPackage.id === id,
    );

    setSettingProductPackage(findProductPackage);
    setIsProductsModalOpen(true);
  };

  const handleProductsModalClose = () => {
    setIsProductsModalOpen(false);
    setSettingProductPackage(undefined);
  };

  const getProducts = (page, search) => {
    setIsLoading(true);

    return bonusProductAPI
      .getList(
        trim(search.status) !== '' ? search.status : undefined,
        trim(search.keyword) !== '' ? search.keyword : undefined,
        trim(search.isPromo) !== '' ? search.isPromo : undefined,
        trim(search.isReferralCode) !== '' ? search.isReferralCode : undefined,
        LIST_PAGE_COUNT,
        page,
      )
      .then(
        (rs) => {
          return rs;
        },
        (error) => {
          alertError(error);
        },
      )
      .then((rs) => {
        setIsLoading(false);
        return rs;
      });
  };

  const removeProduct = (productPackageId, packageProductId) => {
    setIsLoading(true);

    return productPackageAPI
      .removePackageProduct(packageProductId)
      .then(
        (rs) => {
          refreshSettingProductPackage(productPackageId);
          Swal.fire({
            title: '移除完成',
            icon: 'success',
          });
          return true;
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const addProduct = (
    productPackageId,
    productId,
    isGift,
    count,
    expriyDay,
  ) => {
    setIsLoading(true);

    productPackageAPI
      .addProduct(productPackageId, productId, isGift, count, expriyDay)
      .then(
        () => {
          refreshSettingProductPackage(productPackageId);
          Swal.fire({
            title: '新增完成',
            icon: 'success',
          });
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={match.url}></BreakCrumbs>

      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center">
          <Grid item xs>
            <Typography variant="h4" color="textPrimary" gutterBottom>
              套券列表
            </Typography>
          </Grid>

          <Grid item xs style={{ marignLeft: 'auto' }}>
            <div style={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                disabled={isLoading}
                onClick={toCreateProductPackage}>
                建立套券
              </Button>
            </div>
          </Grid>
        </Grid>

        <Divider light />

        <Box textAlign="right" mt={2} mb={2}>
          <FormControl
            size="small"
            variant="outlined"
            style={{ marginRight: '12px', minWidth: '150px' }}
            disabled={isLoading}>
            <InputLabel>狀態</InputLabel>
            <Select
              value={search.status}
              name="status"
              onChange={handleChangeSearch}
              label="狀態">
              <MenuItem value="">不設定</MenuItem>
              {map(ProductPackageStatusText, (text, status) => {
                return (
                  <MenuItem value={status} key={status}>
                    {text}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            startIcon={<SearchIcon />}
            disabled={isLoading}
            onClick={() => {
              if (page !== 1) {
                setPage(1);
              } else {
                handleSearch();
              }
            }}>
            搜尋
          </Button>
        </Box>

        {isLoading && <TableLoading />}

        {!isLoading && !productPackages && <TableEmpty message="無套券" />}

        {!isLoading && productPackages && (
          <ProductPackageTable
            productPackages={productPackages}
            page={page}
            setPage={setPage}
            total={productPackageTotal}
            toEditProductPackage={toEditProductPackage}
            handleOpenImgModal={handleOpenImgModal}
            handleOpenProductsModal={handleOpenProductsModal}
          />
        )}
      </Box>

      <ProductPackageImgSettingModal
        isOpen={isImgModalOpen}
        close={handleImgModalClose}
        setCover={setCover}
        uploadImg={uploadImg}
        deleteImg={deleteImg}
        productPackage={settingProductPackage}
        isLoading={isLoading}
      />

      <ProductPackageProductSettingModal
        isOpen={isProductsModalOpen}
        close={handleProductsModalClose}
        isLoading={isLoading}
        productPackage={settingProductPackage}
        getProducts={getProducts}
        removeProduct={removeProduct}
        addProduct={addProduct}
      />
    </div>
  );
};

export default ProductPackageList;
