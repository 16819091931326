import { Box, Button, Card, CardContent, Chip } from '@material-ui/core';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImgUpload from '../ImgUpload';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '50vw',
  },
  images: {
    marginTop: theme.spacing(3),
  },
  imgs: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    gap: theme.spacing(2),
    margin: theme.spacing(2, 'auto'),
  },
  info: {
    backgroundColor: '#eee',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    textAlign: 'left',
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  title: {
    fontSize: '1.6rem',
    fontWeight: '600',
  },
  desc: {
    color: '#797777',
    fontWeight: '400',
    fontSize: '0.8rem',
  },
  prices: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    color: '#b98686',
  },
  price: {
    flexBasis: '47%',
    textAlign: 'center',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    backgroundColor: '#ffe4e4',
  },
  priceNumber: {
    fontSize: '2rem',
  },
  priceMin: {
    margin: theme.spacing(0, 1),
    fontWeight: '600',
    color: '#75692b',
  },
}));

const GuestCardTypeCard = (props) => {
  const classes = useStyles();
  const { guestCardType, uploadFrontImg, uploadBackImg, openEditModal } = props;

  return (
    <Card className={classes.root}>
      <CardContent>
        <div className={classes.info}>
          <div className={classes.titleRow}>
            <div>
              <div className={classes.title}>{guestCardType.name}</div>
              <div className={classes.desc}>{guestCardType.description}</div>
            </div>

            <div>
              <Chip
                label={guestCardType.isActivate ? '啟用' : '停用'}
                color={guestCardType.isActivate ? 'primary' : 'default'}
                size="small"
                icon={
                  guestCardType.isActivate ? (
                    <VisibilityRoundedIcon />
                  ) : (
                    <VisibilityOffRoundedIcon />
                  )
                }
              />
            </div>
          </div>

          <Box textAlign="right">
            <Button
              variant="text"
              color="primary"
              startIcon={<EditRoundedIcon />}
              onClick={() => {
                openEditModal(guestCardType);
              }}
              size="small">
              編輯
            </Button>
          </Box>

          <div className={classes.prices}>
            <div className={classes.price}>
              <div className={classes.priceNumber}>
                <AttachMoneyRoundedIcon />
                {guestCardType.initialPrice}
              </div>
              前
              <span className={classes.priceMin}>
                {guestCardType.initialMinutes}
              </span>
              分
            </div>

            <div className={classes.price}>
              <div className={classes.priceNumber}>
                <AttachMoneyRoundedIcon />
                {guestCardType.periodPrice}
              </div>
              每
              <span className={classes.priceMin}>
                {guestCardType.periodMinutes}
              </span>
              分
            </div>

            <div className={classes.price}>
              可優惠
              <span className={classes.priceMin}>
                {guestCardType.discountMinutes}
              </span>
              分
            </div>

            <div className={classes.price}>
              單次最高收費
              <span className={classes.priceMin}>
                {guestCardType.maxChargeMinutes}
              </span>
              分
            </div>
          </div>
        </div>

        <div className={classes.images}>
          <div className={classes.imgs}>
            <ImgUpload
              imgUrl={guestCardType.frontImgUrl}
              emptyText="正面圖檔"
              uploadImage={(file) => {
                uploadFrontImg(guestCardType.id, file);
              }}
            />

            <ImgUpload
              imgUrl={guestCardType.backImgUrl}
              emptyText="背面圖檔"
              uploadImage={(file) => {
                uploadBackImg(guestCardType.id, file);
              }}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default GuestCardTypeCard;
