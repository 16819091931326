import instance from './API';
import { trim } from 'lodash';

const guestCardAPI = {
  punch: (code) => {
    return instance.post('/GuestCard/Punch', null, {
      params: {
        code: code,
      },
    });
  },
  getGuestCardTypes: () => {
    return instance.get('/GuestCardType');
  },
  createGuestCardType: (guestCardType) => {
    const createParams = new URLSearchParams();

    createParams.append('Name', guestCardType.name);
    createParams.append('Description', guestCardType.description);
    createParams.append('PeriodMinutes', guestCardType.periodMinutes);
    createParams.append('PeriodPrice', guestCardType.periodPrice);
    createParams.append('InitialMinutes', guestCardType.initialMinutes);
    createParams.append('InitialPrice', guestCardType.initialPrice);
    createParams.append('DiscountMinutes', guestCardType.discountMinutes);
    createParams.append('MaxChargeMinutes', guestCardType.maxChargeMinutes);
    createParams.append('IsActivate', guestCardType.isActivate);

    return instance.post(`/GuestCardType`, createParams);
  },
  updateGuestCardType: (typeId, guestCardType) => {
    const updateParams = new URLSearchParams();

    updateParams.append('Name', guestCardType.name);
    updateParams.append('Description', guestCardType.description);
    updateParams.append('PeriodMinutes', guestCardType.periodMinutes);
    updateParams.append('PeriodPrice', guestCardType.periodPrice);
    updateParams.append('InitialMinutes', guestCardType.initialMinutes);
    updateParams.append('InitialPrice', guestCardType.initialPrice);
    updateParams.append('DiscountMinutes', guestCardType.discountMinutes);
    updateParams.append('MaxChargeMinutes', guestCardType.maxChargeMinutes);
    updateParams.append('IsActivate', guestCardType.isActivate);

    return instance.put(`/GuestCardType/${typeId}`, updateParams);
  },
  uploadFrontImg: (typeId, file) => {
    const uploadFormData = new FormData();

    uploadFormData.append('File', file);

    return instance.post('/GuestCardType/FrontImg', uploadFormData, {
      params: {
        typeId: typeId,
      },
    });
  },
  uploadBackImg: (typeId, file) => {
    const uploadFormData = new FormData();

    uploadFormData.append('File', file);

    return instance.post('/GuestCardType/BackImg', uploadFormData, {
      params: {
        typeId: typeId,
      },
    });
  },
  getGuestCards: (search, count, page) => {
    return instance.get('/GuestCard', {
      params: {
        page: page,
        count: count,
        status: trim(search.status) === '' ? undefined : search.status,
        code: trim(search.code) === '' ? undefined : search.code,
      },
    });
  },
  generateTypeCards: (count) => {
    return instance.post('/GuestCard', null, {
      params: {
        count: count,
      },
    });
  },
  getGuestCardHistory: (search, count, page) => {
    return instance.get('/GuestCardHistory', {
      params: {
        page: page,
        count: count,
        startAt: trim(search.startAt) !== '' ? search.startAt : undefined,
        endAt: trim(search.endAt) !== '' ? search.endAt : undefined,
        cardId: trim(search.cardId) !== '' ? search.cardId : undefined,
        typeId: trim(search.typeId) !== '' ? search.typeId : undefined,
        status: trim(search.status) !== '' ? search.status : undefined,
      },
    });
  },
  checkHistoryType: (historyId, typeId) => {
    return instance.post('/GuestCard/CheckOut', null, {
      params: {
        historyId: historyId,
        typeId: typeId,
      },
    });
  },
  getGuestCardInfo: (cardCodeOrUserId) => {
    return instance.get(`/GuestCardHistory/${cardCodeOrUserId}`);
  },
};

export default guestCardAPI;
